import SwaggerParser from '@apidevtools/swagger-parser';
import { Parser as AsyncParser } from '@asyncapi/parser';
import type { MaybeAsyncAPI } from '@asyncapi/parser/esm/types';
import {
  type DevPortalAPI,
  type ExtendedAsyncAPISpec,
  type ExtendedOpenAPISpec,
  type OpenAPI,
  type SpecWarnings,
  checkVersion,
  cleanSchema,
  enrichSchema,
  extendWithEndpoints,
  findDuplicateKeys,
  getAsyncAPIWarnings,
  getOpenAPIWarnings,
  parseSpecType,
  swaggerParserOptions,
} from '@newdaycards/spec-tools';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import type { PageProps } from 'gatsby';
import { ThemeProvider } from 'next-themes';
import { useState } from 'react';
import { MdCancel, MdCheckBox, MdWarning } from 'react-icons/md';
import PreviewLayout, { ErrorBoundary, ErrorFallback } from '../layouts/preview';
import { ReferenceLayout } from '../layouts/reference';

const APIPreview = ({ location }: PageProps) => {
  const [error, setError] = useState<Error | undefined>();
  const [warnings, setWarnings] = useState<SpecWarnings | null>(null);
  const [asyncWarnings, setAdditionalWarnings] = useState<SpecWarnings | null>(null);
  const [content, setContent] = useState('');
  const [apiSpec, setApiSpec] = useState<DevPortalAPI | null>(null);

  /* istanbul ignore next */
  const validateSpec = async (value: string) => {
    try {
      const spec = parseSpecType(JSON.parse(value));

      if (spec.type === 'openapi') {
        checkVersion(spec.schema);
        setWarnings({
          ...getOpenAPIWarnings(spec),
          ...findDuplicateKeys(value),
        });
        setAdditionalWarnings(null);
        await new SwaggerParser().validate(structuredClone(spec.schema as OpenAPI), swaggerParserOptions);
      } else if (spec.type === 'workflow') {
        throw new Error('Workflow is not supported');
      } else {
        const asyncParser = new AsyncParser();
        const diagnostics = await asyncParser.validate(spec.schema as unknown as MaybeAsyncAPI);
        setWarnings({
          diagnostics: diagnostics
            .filter((diagnostic) => diagnostic.severity === 0)
            .map(
              (diagnostic) =>
                `${diagnostic.message} ${diagnostic.path.length > 0 ? ` > ${diagnostic.path.join(' > ')}` : ''}`,
            )
            .join('\n'),
          ...findDuplicateKeys(value),
          ...getAsyncAPIWarnings(spec),
        });
        setAdditionalWarnings({
          diagnostics: diagnostics
            .filter((diagnostic) => diagnostic.severity === 1)
            .map(
              (diagnostic) =>
                `${diagnostic.message} ${diagnostic.path.length > 0 ? ` > ${diagnostic.path.join(' > ')}` : ''}`,
            )
            .join('\n'),
        });
      }

      const commitDateUTC = new Date().getTime();
      const enrichedApi = enrichSchema(spec, commitDateUTC);
      const cleanedApi = cleanSchema(enrichedApi);
      setApiSpec(cleanedApi);
    } catch (err) {
      setApiSpec(null);
      setError(err as Error);
    }
  };

  const renderAsyncErrors = (warningName: string, warningText: string) =>
    warningText ? (
      warningText.split('\n').map((diagnostic) => (
        <div className="flex items-center gap-2" data-testid="warnings" key={warningName + diagnostic}>
          <MdCancel className="text-red-500 dark:text-red-300 size-4" />
          <p>{diagnostic}</p>
        </div>
      ))
    ) : (
      <div className="flex items-center gap-2" data-testid="warnings">
        <MdCheckBox className="text-green-700 dark:text-green-300 size-4" />
        <p className="font-medium">No Async Parser errors found</p>
      </div>
    );

  return (
    <ThemeProvider attribute="class" enableSystem={false} disableTransitionOnChange>
      <PreviewLayout
        title="API Previewer"
        fileType=".json"
        linkToGuide="https://github.com/NewDayTechnology/API-Design"
        validateSpec={validateSpec}
        content={content}
        setContent={setContent}
        location={location}
        isGreyLayout
      >
        <span data-pagefind-meta="title:API Previewer" />
        <span data-pagefind-meta="description:This tool allows you to previewer your Swagger (.json) files to see how they will render on the Developer Portal." />
        {content && warnings && Object.keys(warnings).length > 0 && (
          <div className="flex flex-col gap-2 border p-2 rounded border-amber-500 dark:border-amber-300 bg-white dark:bg-dark-800 mb-7">
            {Object.entries(warnings).map(([warningName, warningText]) => {
              const [warningTitle, warningsDetails] = warningText.split(':\n');
              return (
                <div key={warningName}>
                  {warningName === 'diagnostics' ? (
                    renderAsyncErrors(warningName, warningText)
                  ) : (
                    <div key={warningName}>
                      <p data-testid="warnings" className="flex items-center gap-2">
                        {warningTitle.includes('⛔') ? (
                          <MdCancel className="text-red-500 dark:text-red-300 size-4" />
                        ) : (
                          <MdWarning className="text-amber-500 dark:text-amber-300 size-4" />
                        )}
                        {warningTitle.replaceAll(/-\s|\s{2,}|⛔\s*|⚠️\s*/g, '').trim()}
                      </p>
                      {warningsDetails && (
                        <Accordion type="single" collapsible>
                          <AccordionItem value="warnings">
                            <AccordionTrigger
                              withIcon
                              variant="accordion"
                              className="my-2 flex-row-reverse gap-2 py-1 px-3 text-xs font-normal justify-end dark:bg-dark-850"
                            >
                              Details
                            </AccordionTrigger>
                            <AccordionContent className="flex flex-col gap-2 mx-2">
                              {warningsDetails.split('\n').map((detail) => (
                                <div className="flex items-center gap-2" data-testid="warnings" key={detail.trim()}>
                                  {detail.replace(/[`\s]/g, '')}
                                </div>
                              ))}
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            {asyncWarnings && Object.values(asyncWarnings).join() && (
              <div>
                <p data-testid="warnings" className="flex items-center gap-2">
                  <MdWarning className="text-amber-500 dark:text-amber-300 size-4" />
                  Additional Async Parser warnings
                </p>
                <Accordion type="single" collapsible>
                  <AccordionItem value="warnings">
                    <AccordionTrigger
                      withIcon
                      className="my-2 flex-row-reverse gap-2 py-1 px-3 text-xs font-normal justify-end"
                    >
                      Details
                    </AccordionTrigger>
                    <AccordionContent className="flex flex-col gap-2 mx-2">
                      {Object.entries(asyncWarnings).map(([warningName, warningText]) =>
                        warningText.split('\n').map((diagnostic) => (
                          <div
                            className="flex items-center gap-2"
                            data-testid="warnings"
                            key={warningName + diagnostic}
                          >
                            <MdWarning className="text-amber-500 dark:text-amber-300 size-4" />
                            <p>{diagnostic}</p>
                          </div>
                        )),
                      )}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
            )}
          </div>
        )}
        {error ? (
          <ErrorFallback error={error} resetErrorBoundary={() => setError(undefined)} />
        ) : (
          <ErrorBoundary>
            {content && apiSpec && (
              <ReferenceLayout
                spec={extendWithEndpoints(apiSpec.schema) as ExtendedOpenAPISpec | ExtendedAsyncAPISpec}
                isPreview
                pagePath="/api-preview"
                deprecated={false}
              />
            )}
          </ErrorBoundary>
        )}
      </PreviewLayout>
    </ThemeProvider>
  );
};

export default APIPreview;
